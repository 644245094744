@import "../../assets/scss/abstract";

@include b(viewer) {
  position: fixed;
  height: 100%;
  width: 100%;

  @include e(slider) {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  @include e(fullScreenBtn) {
    position: fixed;
    bottom: 10px;
    right: 5px;

    & .pst-button {
      width: 40px;
      height: 40px;
      background-color: rgba(12, 13, 13, 0.6);
      border-radius: 50%;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      box-shadow: none;
      mix-blend-mode: normal;
      backdrop-filter: blur(20px);
    }
  }

  @include e(fullscreenTooltip) {
    position: fixed;
    top: 20px;
    left: 50px;
    display: none;

    @media (orientation: landscape) {
      display: block;
    }
  }

  @include e(audioPlay) {
    position: fixed;
    bottom: 55px;
    transition: all 0.3s ease;

    @include m(show) {
      right: 0px;
    }

    @include m(hide) {
      right: -60px;
    }
  }

  @include e(pauseAudio) {
    position: fixed;
    bottom: 115px;
    transition: all 0.3s ease;

    @include m(show) {
      right: 0px;
    }

    @include m(hide) {
      right: -45px;
    }
  }

  @include e(dictors) {
    position: fixed;
    transition: all 0.3s ease;

    & .pst-audioPlay:first-child {
      margin-bottom: 5px;
    }

    @include m(bottomUp) {
      bottom: 160px;
    }

    @include m(bottomDown) {
      bottom: 115px;
    }

    @include m(show) {
      right: 0px;
    }

    @include m(hide) {
      right: -40px;
    }
  }

  @include e(informAlert) {
    position: relative;
    top: 95px;
    left: calc(50% - 125px);

    @media (orientation: landscape) {
      display: none;
    }
  }
}

@include b(viewerSidebar) {
  @include e(exit) {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1000;
    transition: right 0.3s ease;

    @media (max-width: 820px) {
      top: 10px;
      right: 10px;
    }

    @include m(hidden) {
      right: -65px;
    }
  }

  @include e(slideCounter) {
    position: absolute;
    top: 20px;
    z-index: 5;
    transition: left 0.3s ease;

    @include m(show) {
      left: 20px;

      @media (max-width: 768px) {
        left: 10px;
      }
    }

    @include m(hidden) {
      left: -70px;
    }

    @media (max-width: 768px) {
      top: 10px;
    }
  }

  @include e(accessLabel) {
    position: absolute;
    left: 20px;
    z-index: 5;
    transition: bottom 0.3s ease;

    @include m(show) {
      bottom: 20px;

      @media (max-width: 768px) {
        bottom: 10px;
      }
    }

    @include m(hidden) {
      bottom: -70px;
    }

    @media (max-width: 768px) {
      left: 10px;
    }

    @media (max-width: 450px) {
      display: none;
    }
  }
}

@include b(slideList) {
  transition: left 0.7s ease;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  background-color: $pst-black;

  @include e(slide) {
    list-style: none;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include m(newYear) {
    background-color: $pst-white;
  }
}
