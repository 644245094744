/* =========================
Custom Input range
=============================*/

/* Input range - chrome and safari */
input[type='range'] {
  --range-progress: 0;

  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background: #ccc;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* Input range - firefox */
input[type='range']::-moz-range-track {
  position: relative;
  background: #ccc;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* played progress length - Chrome & safari*/
input[type='range']::before {
  content: '';
  height: 2px;
  background: #f50;
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

/* played progress length - firefox */
input[type='range']::-moz-range-progress {
  background: #f50;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2px;
}

/* slider thumb - chrome and safari */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: relative;
}

.volumn::-webkit-slider-thumb {
  background-color: #f50;
}

/* dragging thumb - chrome and safari */
input[type='range']:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background-color: #f50;
}

/* slider thumb - firefox */
input[type='range']::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #f50;
  cursor: pointer;
  border: transparent;
  position: relative;
}
/* dragging thumb - firefox */
input[type='range']:active::-moz-range-thumb {
  transform: scale(1.2);
  background-color: #f50;
}
